import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'

function UpcomingMovie(props){
  const [info, setInfo] = useState(false)

  function handleInfo(){
    if (info) setInfo(false)
    else setInfo(true)
  }

  function Info(){
    if(info){
      return (
        <Card.ImgOverlay>
        <Card.Title>{props.m.title}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{props.m.year}</Card.Subtitle>
        <Card.Text>Genres: {props.m.genres.map(g=>`${g} `)}</Card.Text>
        <Card.Text>
          Rating: {props.m.rating}/10
        </Card.Text>
        </Card.ImgOverlay>
      )
    }else return <></>
  }

  function Image(){
    if(!info) return <Card.Img variant="top" src={props.m.medium_cover_image}/>
    else return <Card.Img variant="top" src="http://via.placeholder.com/230x345/000000?text=%20"/>
  }


  return(
  <Card className="bg-dark text-white" style={{ width: '18rem' }}>
    <Image/>
    <Info/>
  </Card>
  )
}

export default UpcomingMovie
import React, { useState } from 'react'
import CardColumns from 'react-bootstrap/CardColumns'
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Movie from './Movie'


import './App.css';

function Browse() {
  const [results, setResults] = useState([])
  const [data, setData] = useState()
  const [screen,setScreen] = useState('genre')
  const [genre,setGenre] = useState()
  const genres = ["Action","Adventure","Animation","Biography","Comedy","Crime","Documentary","Drama","Family","Fantasy","Film Noir","Game-Show","History","Horror","Musical","Music","Mystery","News","Reality-TV","Romance","Sci-Fi","Short","Sport","Talk-Show","Thriller","War","Western"]

  function onClick(value, page = 1){
    setScreen('movies')
    var xhr = new XMLHttpRequest()
    xhr.addEventListener('load', () => {
      const json = JSON.parse(xhr.responseText)
      console.log(json)
      setGenre(value)
      setData(json)
      setResults(json.data.movies.map(m=>m))
    })
    xhr.open('GET', `https://yts.mx/api/v2/list_movies.json?genre=${value}&sort_by=year&page=${page}&limit=50`)
    xhr.send()
  }

  function ListGenres(){
    if(screen === 'genre')
    return(
      <CardColumns>
        {genres.map(g=>{
          return <Genre key={g} g={g}/>
        })}
      </CardColumns>
    )
    else return <></>
  }
  
  function Genre(props){
    return(
      <Card style={{ width: '14rem' }}>
        {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
        <Card.Body>
          <Card.Title>{props.g}</Card.Title>
          <Button variant="primary" onClick={()=>onClick(props.g)}>Browse</Button>
        </Card.Body>
      </Card>
    )
  }

  function ListMovies(){
    if(results.length > -1 && screen === 'movies'){
      return <CardColumns>
        {results.map((m, index) => {
          return <Movie key={m.title} m={m} Request={Request}/>
        })}
      </CardColumns>
    }else{
      return <></>
    }
  }

  function ListPages(){
    if (data && screen === 'movies'){
      const maxPage = Math.ceil(data.data.movie_count / data.data.limit)
      let pageArr = []
      if (data.data.page_number > 1 ) pageArr.push({name: 'Prev', page: data.data.page_number - 1})
      if (data.data.page_number !== maxPage) pageArr.push({name: 'Next', page:data.data.page_number + 1})
      return(
        <div>{pageArr.map(p=>{
          return <Button key={p.page} variant="primary" onClick={()=>onClick(genre,p.page)}>{p.name}({p.page})</Button>
        })}</div>
      )
    }else return <></>
  }

  function Request(movie){
    const xhr = new XMLHttpRequest()
    xhr.open('GET', `/add/${movie}`)
    xhr.send()
  }
  
  function BackButton(){
    if(screen !== 'genre') return <Button onClick={()=>setScreen('genre')}>Back</Button>
    else return <></>
  }

  return (
    <Container>
      <BackButton/>
      <ListGenres/>
      <ListMovies/>
      <ListPages/>
    </Container>
  )
}

export default Browse

import React, { useState, useEffect } from 'react'
import axios from 'axios'
import CardColumns from 'react-bootstrap/CardColumns'
import UpcomingMovie from './UpcomingMovie'

export default function Home(){
  const [results,setResults] = useState()
  // useEffect(async () => {
  //   var xhr = new XMLHttpRequest()
  //   xhr.addEventListener('load', () => {
  //     const json = JSON.parse(xhr.responseText)
  //     console.log(json)
  //     setResults(json.data.movies)
  //   })
  //   xhr.open('GET', `https://yts.mx/api/v2/list_movies.json?`)
  //   xhr.send()
  // })

  useEffect(() => {
    async function fetchData(){
      const result = await axios(
        'https://yts.mx/api/v2/list_movies.json?limit=10',
      )
      console.log('called')
      setResults(result.data.data.movies)
    }
    if(!results)fetchData()
  })

  function ListMovies(){
    if(results){
      return(
        <CardColumns>
          {results.map((m, index) => {
          return <UpcomingMovie key={m.title} m={m}/>
          })}
        </CardColumns>
      )
    }else return <div>Loading...</div>


  }

  return(
    <div>
      Recently Added:
      <ListMovies/>
    </div>
  )
}